import type { VFC, ReactNode } from 'react';
import { Fragment } from 'react';

import { useAuth } from '@/context/authentication';
import FullScreen from '@/components/Placeholder/FullScreen';
import Redirect from '@/components/Redirect';

interface Props {
  children: ReactNode;
}

/**
 * @function PrivateWall
 * @param props
 */
const PrivateWall: VFC<Props> = props => {
  const { children } = props;
  const { loading, isAuth } = useAuth();

  if (loading) {
    return <FullScreen />;
  }
  if (!loading && !isAuth) {
    return <Redirect to="/sign-in" Placeholder={FullScreen} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default PrivateWall;
