import type { VFC } from 'react';
import Image from 'next/image';
import Typography from '@mui/material/Typography';

import { containerCx } from './styles';

/**
 * @function FullScreen Presentational component
 */
const FullScreen: VFC<unknown> = () => {
  return (
    <div css={containerCx}>
      <Image src="/images/best-home.png" alt="Best Home" width={100} height={100} unoptimized />
      <Typography align="center" variant="splash" component="h1">
        Best Home
      </Typography>
    </div>
  );
};

export default FullScreen;
