import DashboardIcon from '@mui/icons-material/Dashboard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BusinessIcon from '@mui/icons-material/Business';

import type { MenuItems } from './types';

export const BESTHOME_ITEMS: MenuItems[] = [
  {
    title: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
  },
  {
    title: 'Company',
    url: '/company',
    icon: BusinessIcon,
  },
  {
    title: 'Home',
    url: '/home',
    icon: AdminPanelSettingsIcon,
    child: [
      {
        title: 'Agent Menu',
        url: '/home/agent-menu',
        icon: MenuIcon,
      },
      {
        title: 'Agent Component',
        url: '/home/agent-component',
        icon: WidgetsIcon,
      },
      {
        title: 'Customer Menu',
        url: '/home/customer-menu',
        icon: MenuIcon,
      },
      {
        title: 'Customer Component',
        url: '/home/customer-component',
        icon: WidgetsIcon,
      },
    ],
  },
  {
    title: 'User',
    url: '/user',
    icon: PeopleIcon,
    child: [
      {
        title: 'Standard User',
        url: '/user/standard-user',
        icon: PeopleIcon,
      },
      {
        title: 'Internal User',
        url: '/user/internal-user',
        icon: PeopleIcon,
      },
    ],
  },
  {
    title: 'Property',
    icon: HomeWorkIcon,
    child: [
      {
        title: 'Property Group',
        url: '/property/property-group',
        icon: HomeWorkIcon,
      },
      {
        title: 'Property Type',
        url: '/property/property-type',
        icon: HomeWorkIcon,
      },
      {
        title: 'Sub Type',
        url: '/property/sub-type',
        icon: HomeWorkIcon,
      },
    ],
  },
  {
    title: 'Notification',
    icon: NotificationsActiveIcon,
    child: [
      {
        title: 'Template',
        url: '/notification/template',
        icon: StickyNote2Icon,
      },
    ],
  },
  {
    title: 'Location',
    icon: LocationOnIcon,
    child: [
      {
        title: 'Province',
        url: '/location/province',
        icon: MapIcon,
      },
      {
        title: 'City',
        url: '/location/city',
        icon: MapIcon,
      },
      {
        title: 'District',
        url: '/location/district',
        icon: MapIcon,
      },
      {
        title: 'City Group',
        url: '/location/city-group',
        icon: MapIcon,
      },
    ],
  },
  {
    title: 'Preserve Advantage',
    url: '/preserve-advantage',
    icon: AddBoxIcon,
  },
  {
    title: 'Area',
    url: '/area',
    icon: MapIcon,
  },
  {
    title: 'Developer',
    url: '/developer',
    icon: EngineeringIcon,
  },
  {
    title: 'Tag',
    url: '/tag',
    icon: LocalOfferIcon,
  },
];
