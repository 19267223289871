import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import type { MenuItems } from './types';

export const COMPANYINVESTOR_ITEMS: MenuItems[] = [
  {
    title: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
  },
  {
    title: 'Investor Report',
    icon: AssessmentIcon,
    child: [
      {
        title: 'Sales Report',
        url: '/investor-report/sales',
        icon: PostAddIcon,
      },
      {
        title: 'CRM Export',
        url: '/investor-report/crm-data',
        icon: PostAddIcon,
      },
      {
        title: 'Product Siteplan',
        url: '/investor-report/product-siteplan',
        icon: PostAddIcon,
      },
    ],
  },
  {
    title: 'Customer Monitor',
    url: '/investor-report/customer-monitoring',
    icon: AccountBoxIcon,
  },
];
