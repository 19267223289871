import { css } from '@emotion/react';

export const toolbarCx = css`
  padding-right: 24px;
`;

export const toggleCx = css`
  margin-right: 36px;

  &.open {
    display: none;
  }
`;

export const openCx = css`
  display: none;
`;

export const titleCx = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
