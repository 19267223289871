import type { VFC, ReactNode } from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import requestTimeout from '@/utils/dom/requestTimeout';

import PrivateWall from './PrivateWall';
import Header from './Header';
import Sidebar from './Sidebar';
import Main from './Main';

import { containerCx } from './styles';

interface Props {
  children: ReactNode;
}

/**
 * @function Dashboard
 */
const Dashboard: VFC<Props> = props => {
  const { children } = props;
  const [open, setOpen] = useState(false);

  const autoRef = useRef(false);

  /**
   * @function handleOnToggle
   */
  const handleOnToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  useEffect(() => {
    if (autoRef.current) {
      return;
    }

    const timeout = requestTimeout(() => {
      setOpen(true);
      autoRef.current = true;
    }, 1000);

    return () => {
      timeout.cancel();
    };
  }, []);

  return (
    <PrivateWall>
      <Box className={containerCx}>
        <Header open={open} onToggle={handleOnToggle} />
        <Sidebar open={open} onToggle={handleOnToggle} />
        <Main component="main">
          <Toolbar />
          {children}
        </Main>
      </Box>
    </PrivateWall>
  );
};

export default Dashboard;
