import { css } from '@emotion/css';

export const toolbarCx = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 0px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const toggleCx = css`
  margin-right: 36px;

  &.open {
    display: none;
  }
`;
