import type { VFC } from 'react';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';

import { useProfile } from '@/context/profile';
import { MenuLink, MenuWithChild } from './Sidemenu';
import { Drawer, DrawerHeader } from './Drawer';

import { BESTHOME_ITEMS } from './BesthomeConstants';
import { COMPANY_ITEMS } from './CompanyConstants';
import { COMPANYCRMONLY_ITEMS } from './CompanyConstantsCRMOnly';
import { COMPANYINVESTOR_ITEMS } from './CompanyInvestor';
import { toolbarCx } from './styles';

interface Props {
  open: boolean;
  onToggle: () => void;
}

/**
 * @function Sidebar
 */
const Sidebar: VFC<Props> = props => {
  const { open, onToggle } = props;
  const { companyID, isInvestor } = useProfile();

  let sideMenu: any[] = [];
  if (companyID === -1) {
    sideMenu = BESTHOME_ITEMS;
  } else if (companyID === 5 || companyID === 6 || companyID === 7 || companyID === 8 || companyID === 9) {
    sideMenu = COMPANYCRMONLY_ITEMS;
  } else {
    sideMenu = COMPANY_ITEMS;
  }

  if (isInvestor === 1) {
    sideMenu = COMPANYINVESTOR_ITEMS;
  }

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader className={toolbarCx}>
        <IconButton onClick={onToggle}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List component="nav">
        {sideMenu.map((menu, index) => {
          const { title, url, icon, child = [] } = menu;
          if (child.length !== 0) {
            return <MenuWithChild key={index} title={title} icon={icon} child={child} drawerOpen={open} />;
          }
          if (url) {
            return <MenuLink key={index} title={title} url={url} icon={icon} />;
          }
          return null;
        })}
      </List>
      <Toolbar />
    </Drawer>
  );
};

export default Sidebar;
