import type { VFC, ComponentType } from 'react';
import { memo } from 'react';

import Link from 'next/link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  url: string;
  icon?: ComponentType;
  padded?: boolean;
}

/**
 * @function MenuLink
 * @param props
 */
const MenuLink: VFC<Props> = props => {
  const { title, url, icon: Icon, padded = false } = props;
  return (
    <Link href={url} prefetch={false} passHref>
      <ListItem disablePadding component="a">
        <ListItemButton sx={padded ? { pl: 4 } : undefined}>
          {Icon ? (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          ) : null}
          <ListItemText disableTypography>
            <Typography variant="body3" component="span">
              {title}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

export default memo(MenuLink);
