import type { VFC, MouseEvent } from 'react';
import { useState, useCallback, useEffect } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import { useProfile } from '@/context/profile';
import { useAuth } from '@/context/authentication';
import { useNotifUpdater } from '@/context/notification';

import { containerCx, menuCx } from './styles';

/**
 * @function Profile
 */
const Profile: VFC<unknown> = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const { events } = useAuth();
  const { loading: profileLoading, email } = useProfile();
  const { push } = useNotifUpdater();

  /**
   * @function handleOnOpen
   * @param event
   */
  const handleOnOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  /**
   * @function handleOnClose
   */
  const handleOnClose = useCallback(() => {
    setAnchor(null);
  }, []);

  /**
   * @function handleOnLogout
   */
  const handleOnLogout = useCallback(() => {
    events.emit('@authentication/logout');
    setAnchor(null);
  }, [events]);

  /**
   * @function handleOnLogoutError
   * @param string
   */
  const handleOnLogoutError = useCallback(
    (message: string) => {
      push({
        message: message,
        variant: 'error',
        duration: 3000,
      });
    },
    [push],
  );

  useEffect(() => {
    events.on('@authentication/logout-error', handleOnLogoutError);
    return () => {
      events.off('@authentication/logout-error', handleOnLogoutError);
    };
  }, [events, handleOnLogoutError]);

  return (
    <Box css={containerCx}>
      <IconButton onClick={handleOnOpen} sx={{ p: 0 }}>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Menu
        css={menuCx}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={handleOnClose}
      >
        <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{profileLoading ? <Skeleton /> : email}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOnLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Profile;
