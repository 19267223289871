import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import HouseIcon from '@mui/icons-material/House';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RedeemIcon from '@mui/icons-material/Redeem';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PostAddIcon from '@mui/icons-material/PostAdd';

import type { MenuItems } from './types';

export const COMPANYCRMONLY_ITEMS: MenuItems[] = [
  {
    title: 'User',
    url: '/user',
    icon: PeopleIcon,
    child: [
      {
        title: 'Internal User',
        url: '/user/internal-user',
        icon: PeopleIcon,
      },
      {
        title: 'Standard User',
        url: '/user/standard-user',
        icon: PeopleIcon,
      },
      {
        title: 'Team',
        url: '/user/team',
        icon: PeopleIcon,
      },
      {
        title: 'Team Member',
        url: '/user/team-member',
        icon: PeopleIcon,
      },
    ],
  },
  {
    title: 'Customer',
    url: '/customer',
    icon: AccountBoxIcon,
  },
  {
    title: 'Project',
    url: '/project',
    icon: AccountTreeIcon,
  },
  {
    title: 'Product',
    url: '/product',
    icon: HouseIcon,
  },
  {
    title: 'CRM',
    icon: HandshakeIcon,
    child: [
      {
        title: 'Activity',
        url: '/crm/activity',
        icon: PlayArrowIcon,
      },
      {
        title: 'Channel',
        url: '/crm/channel',
        icon: SubscriptionsIcon,
      },
      {
        title: 'Channel Event',
        url: '/crm/channel-event',
        icon: CalendarMonthIcon,
      },
    ],
  },
  {
    title: 'Sales',
    icon: MonetizationOnIcon,
    child: [
      {
        title: 'Transaction',
        url: '/sales/transaction',
        icon: ReceiptLongIcon,
      },
      {
        title: 'Commission',
        url: '/sales/commission',
        icon: RedeemIcon,
      },
      {
        title: 'Cash Reward',
        url: '/sales/cash-reward',
        icon: RedeemIcon,
      },
      {
        title: 'Non Cash Reward',
        url: '/sales/non-cash-reward',
        icon: RedeemIcon,
      },
    ],
  },
  {
    title: 'Report',
    icon: AssessmentIcon,
    child: [
      {
        title: 'Sales Report',
        url: '/report/sales',
        icon: PostAddIcon,
      },
      {
        title: 'CRM Export',
        url: '/report/crm-data',
        icon: PostAddIcon,
      },
    ],
  },
];
