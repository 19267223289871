import type { VFC } from 'react';

import Link from 'next/link';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import BrandIcon from '@/components/Icons/BrandIcon';

import { N0 } from '@/styles/color';

import AppBar from './AppBar';
import Profile from './Profile';
import { toggleCx, toolbarCx, openCx, titleCx } from './styles';

interface Props {
  open: boolean;
  onToggle: () => void;
}

/**
 * @function Header
 */
const Header: VFC<Props> = props => {
  const { open, onToggle } = props;

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar css={toolbarCx}>
        <IconButton
          css={[toggleCx, open && openCx]}
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggle}
        >
          <MenuIcon />
        </IconButton>
        <Link href="/" passHref>
          <Typography css={titleCx} variant="h2" component="a" noWrap>
            <BrandIcon sx={{ mr: 1, fontSize: '34px', color: N0 }} />
            BestHome Intools
          </Typography>
        </Link>
        <Profile />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
