import type { VFC, ComponentType } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

interface Props {
  to: string;
  Placeholder?: ComponentType<any>;
}

/**
 * @function Redirect
 * @param props
 */
const Redirect: VFC<Props> = props => {
  const { to, Placeholder } = props;
  const { asPath, push } = useRouter();

  useEffect(() => {
    if (asPath === to) {
      return;
    }

    push(to);
  }, [asPath, push, to]);

  if (Placeholder) {
    return <Placeholder />;
  }
  return null;
};

export default Redirect;
