import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Main = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  flex: '1 1 auto',
  minHeight: '100vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
}));

export default Main;
