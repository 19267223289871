import type { VFC, ComponentType } from 'react';
import { Fragment, memo, useState, useCallback } from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import MenuLink from './MenuLink';
import type { MenuItems } from '../types';

interface Props {
  title: string;
  icon?: ComponentType;
  child: MenuItems[];
  drawerOpen?: boolean;
}

/**
 * @function MenuWithChild
 * @param props
 */
const MenuWithChild: VFC<Props> = props => {
  const { title, icon: Icon, drawerOpen = false, child = [] } = props;
  const [open, setOpen] = useState(false);

  const menuExpanded = open && drawerOpen;

  /**
   * @function handleOnToggle
   */
  const handleOnToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton onClick={handleOnToggle}>
          {Icon ? (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          ) : null}
          <ListItemText disableTypography>
            <Typography variant="body3" component="span">
              {title}
            </Typography>
          </ListItemText>
          {menuExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={menuExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {child.map((menu, index) => {
            if (menu.child && menu.child.length !== 0) {
              return (
                <MenuWithChild
                  key={`mwc-${index}`}
                  title={menu.title}
                  icon={menu.icon}
                  child={menu.child}
                  drawerOpen={drawerOpen}
                />
              );
            }
            if (menu.url) {
              return <MenuLink key={index} title={menu.title} url={menu.url} icon={menu.icon} padded />;
            }
            return null;
          })}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default memo(MenuWithChild);
